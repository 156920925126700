import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Video from "./video";
import BlockContent from "./block-content";
import { StaticImage } from "gatsby-plugin-image";
import { FaBalanceScaleRight } from "react-icons/fa";
export default function Intro() {

  const data = useStaticQuery(graphql`
  query IntroQuery {
    sanityHomePage(_id: {eq: "homePage"}) {
      intro_title
      _rawIntroText
    }
  }
  
  `);

  const { intro_title, _rawIntroText } = data.sanityHomePage;

  return (
    <section>
      <div className="px-6 pt-8 pb-20 m-auto max-w-6xl flex flex-wrap">
        <div className="w-full md:w-1/2 text-center md:px-12">
          <StaticImage src="../images/court.jpg" placeholder="none" alt="Jay Dunham" objectFit="cover" className="h-full rounded-xl shadow-md" />
        </div>
        <div className="w-full md:w-1/2">
          <h1 className="text-3xl md:text-5xl">{intro_title}</h1>
          <BlockContent blocks={_rawIntroText} />
          <Link to="/free-case-evaluation" className="btn btn-secondary">
            Get Help Now
            <FaBalanceScaleRight className="inline ml-3 -mt-1 text-2xl" />
          </Link>
        </div>

      </div>
    </section>
  )
}
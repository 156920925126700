import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Intro from "../components/intro"
import Faqs from "../components/faqs"
import BlogPreview from "../components/blog-preview"
import Cta from "../components/cta"
import OurProcess from "../components/our-process"

export const query = graphql`
query HomePage {
  allSanityPost(limit: 3, sort: {fields: publishedAt, order: DESC}) {
    edges {
      node {
        _id
        author {
          slug {
            current
          }
          name
          image {
            asset {
              gatsbyImageData(height: 60, width: 60, placeholder: BLURRED)
            }
          }
        }
        description
        slug {
          current
        }
        title
        publishedAt
        mainImage {
          asset {
            gatsbyImageData(width: 400, height: 300, fit: CROP)
          }
        }
        categories {
          title
        }
        seo {
          title
          description
          image {
            asset {
              gatsbyImageData(width: 400, height: 250)
            }
          }
        }
      }
    }
  }
  sanityHomePage(_id: {eq: "homePage"}) {
    seo {
      title
      image {
        asset {
          url
        }
      }
      description
    }
  }
}
`;

const IndexPage = props => {
  const posts = props.data?.allSanityPost?.edges ?? [];
  const page = props.data?.sanityHomePage ?? {};
  return (
    <Layout title={page.seo?.title || page.title} description={page.seo?.description || ""} location="">
      <Intro />
      <Faqs />
      <BlogPreview title="From The Blog" subtitle="Want to know more about ERISA law?  Start Here." posts={posts} />
      <OurProcess />
      <Cta />
    </Layout>
  )
}

export default IndexPage

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react"

export default function BlogPreview({ title, subtitle, posts }) {
  return (
    <section className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">{title}</h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            {subtitle}
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map(({ node }) => (
            <div key={node.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-shrink-0">
                <a href={"/blog/" + node.slug.current}>
                  <GatsbyImage className="h-48 w-full object-cover" image={getImage(node.mainImage.asset)} alt={node.title} /></a>
              </div>
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  {node.categories.map((category) => (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-bold bg-gray-100 text-gray-800">
                      {category.title}
                    </span>
                  ))}
                  <a href={"/blog/" + node.slug.current} className="block mt-2 group">
                    <h3 className="text-xl font-semibold text-gray-900 transition-all group-hover:text-secondary group-hover:underline">{node.title}</h3>
                  </a>
                  <p className="mt-3 text-base text-gray-500">{node.description}</p>

                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <a href={node.author.href}>
                      <span className="sr-only">{node.author.name}</span>
                      <GatsbyImage className="w-10 h-10 rounded-full" image={getImage(node.author.image.asset)} alt={node.author.name} />
                    </a>
                  </div>
                  <div className="ml-3">
                    <span className="text-sm font-medium text-gray-900">
                      <a href={"/attorneys/" + node.author.slug.current} className="hover:underline unset">
                        {node.author.name}
                      </a>
                    </span>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={node.publishedAt}>{new Date(node.publishedAt).toLocaleDateString('en-US')}</time>
                      {/* <span aria-hidden="true">&middot;</span>
                      <span>{node.readingTime} read</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}